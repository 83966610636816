import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CommandeForm from '../../core/sysext/Boutique/containers/Commande/components/Form';
import CommandeList from '../../core/sysext/Boutique/containers/Commande/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const CommandesPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  const tabPaths = ['en-attente', 'confirmees', 'archivees'];

  return (
    <Backoffice location={location}>
      {!star || tabPaths.includes(star) ? (
        <CommandeList
          {...props}
          itemPathnamePrefix="/boutique/commandes/"
          tabIndex={star ? tabPaths.findIndex(tab => tab === star) : 3}
        />
      ) : (
        <CommandeForm
          docId={star}
          {...props}
          itemPathnamePrefix="/boutique/commandes/"
        />
      )}
    </Backoffice>
  );
};

export default CommandesPage;
