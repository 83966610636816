import { CommandeData } from '@innedit/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import List from '../../../../../CMS/containers/Boutique/components/new/List';
import commande from '../../params/commande.json';
import Item from './Item';

const CommandeList: FC<
  BoutiqueProps & { itemPathnamePrefix: string; tabIndex?: number }
> = ({ boutique, itemPathnamePrefix, tabIndex }) => {
  const { t } = useTranslation();

  return (
    <CMSView>
      <List
        item={Item}
        itemPathnamePrefix={itemPathnamePrefix}
        model={
          new CommandeData({
            boutique,
            orderDirection: 'desc',
            orderField: 'createdAt',
            params: commande,
          })
        }
        searchShow
        tabIndex={tabIndex}
        title={t('commandes')}
      />
    </CMSView>
  );
};

export default requireBoutique(CommandeList);

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   generateNumero: date => {
//     dispatch(getNewNumero(ownProps.boutique, date))
//       .then(action => dispatch(change(ownProps.formName, 'numero', action.numero)))
//       .catch(error => {
//         throw new Error(`List Commande generateNumero : ${error.message}`);
//       });
//   },
// });

// handleWhenCreateSuccess(ref, item) {
//   // Créer le numéro de la commande
//   this.props.generateNumero(item.date);
// }

// this.state.form.required = ['deliveryDate'];
