import { BoutiqueData, FirebaseFirestore, OrderType } from '@innedit/innedit';
import classnames from 'classnames';
import compact from 'lodash/compact';
import React, { FC, useEffect, useState } from 'react';

import ListItemDate from '../../../../../../components/ListItemDate';
import capitalize from '../../../../../../utils/capitalize';
import ListItem from '../../../../../CMS/containers/Boutique/components/new/Item';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

type TagStateProps = 'danger' | 'info' | 'standard' | 'success' | 'warning';
const Tag: FC<{
  state: TagStateProps;
}> = ({ children, state }) => (
  <span
    className={classnames('status border py-0.5 px-1 text-xs rounded', {
      'bg-gray-100': 'standard' === state,
      'border-danger-200 bg-danger-100 text-danger-800': 'danger' === state,
      'border-info-200 bg-info-100 text-info-800': 'info' === state,
      'border-success-200 bg-success-100 text-success-800': 'success' === state,
      'border-warning-200 bg-warning-100 text-warning-800': 'warning' === state,
    })}
  >
    {children}
  </span>
);

const Item: FC<ListItemProps<OrderType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<OrderType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const item = doc.data() as OrderType;
  const numero = doc.get('numero') || doc.id;

  if (!item) {
    return null;
  }

  const classNames = [];

  if (item.amountRefunded && item.amountRefunded > 0) {
    classNames.push(`paymentStatus_refunded`);
  } else {
    classNames.push(`paymentStatus_${item.paymentStatus}`);
  }

  const date = item.createdAt;

  const name = [];
  if (item.contactCommercialName) {
    name.push(item.contactCommercialName);
  } else {
    if (item.contactFirstName) {
      name.push(item.contactFirstName);
    }
    if (item.contactLastName) {
      name.push(item.contactLastName);
    }
  }

  if (item.contactZip || item.contactCity) {
    const city = [];
    if (item.contactZip) {
      city.push(item.contactZip);
    }
    if (item.contactCity) {
      city.push(capitalize(item.contactCity.trim()));
    }
    name.push(`(${city.join(' ')})`);
  }

  let paymentStatusLabel;
  let paymentStatusState: TagStateProps = 'standard';
  switch (item.paymentStatus) {
    case 'canceled': {
      paymentStatusLabel = 'Paiement annulé';
      break;
    }

    case 'requires_capture': {
      paymentStatusLabel = 'En attente de confirmation';
      paymentStatusState = 'danger';
      break;
    }

    case 'requires_payment_method': {
      paymentStatusLabel = 'Paiement en attente';
      break;
    }

    case 'requires_reservation': {
      paymentStatusLabel = 'En attente de réservation';
      paymentStatusState = 'danger';
      break;
    }

    case 'succeeded': {
      if (!item.amountRefunded || 0 === item.amountRefunded) {
        paymentStatusLabel = 'Paiement validé';
      } else {
        paymentStatusLabel = 'Remboursée';
      }
      paymentStatusState = 'success';
      break;
    }

    case 'waiting': {
      paymentStatusLabel = 'En attente';
      paymentStatusState = 'warning';
      break;
    }

    default:
      paymentStatusLabel = item.paymentStatus;
  }

  let paymentTypeLabel;
  switch (item.paymentType) {
    case 'cheque':
      paymentTypeLabel = 'Chèque';
      break;
    case 'espece':
      paymentTypeLabel = 'Espèce';
      break;
    case 'stripe':
      paymentTypeLabel = 'CB';
      break;
    case 'virement':
      paymentTypeLabel = 'Virement';
      break;
    default:
      paymentTypeLabel = item.paymentType;
  }

  let statusLabel;
  let statusState: TagStateProps = 'standard';
  switch (item.status) {
    case 'canceled':
      statusLabel = 'Commande annulée';
      break;

    case 'requires_reservation':
      statusLabel = 'En attente de réservation';
      statusState = 'danger';
      break;
    default:
      statusLabel = item.status;
  }

  return (
    <ListItem
      actions={{ tooltip: numero, type: 'tooltip' }}
      className={classnames(classNames)}
      contentClassName="flex-auto flex flex-row"
      displayActionId={false}
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <ListItemDate date={date} />
      <div className="flex-auto mx-3 space-x-3">
        <strong className="client">{name.join(' ')}</strong>

        <Tag state="info">{paymentTypeLabel}</Tag>
        <Tag state={paymentStatusState}>{paymentStatusLabel}</Tag>
        {!['waiting', 'succeeded'].includes(item.status) && (
          <Tag state={statusState}>{statusLabel}</Tag>
        )}
        {item.paymentLastError && (
          <Tag state="danger">{item.paymentLastError}</Tag>
        )}
        <ul className="produits mt-2">
          {item.produits &&
            Array.isArray(item.produits) &&
            compact(
              item.produits.map(produit => {
                if (produit.canceledAt) {
                  return null;
                }

                return (
                  <li
                    key={`${doc.id}_${produit.id}${
                      produit.variantId ? `_${produit.variantId}` : ''
                    }`}
                    className="flex mb-1"
                  >
                    {produit.photo && (
                      <div>
                        <img
                          alt={produit.name}
                          height={50}
                          src={`${BoutiqueData.getDomainImagesURL(
                            model.boutique,
                          )}/h_150,w_150/${produit.photo}`}
                          width={50}
                        />
                      </div>
                    )}
                    <div className="infos ml-3 flex flex-col text-sm">
                      <p
                        className={classnames({
                          refunded:
                            produit.amountRefunded &&
                            produit.amountRefunded > 0,
                        })}
                      >
                        <span className="mr-2">{produit.name} :</span>

                        <span
                          className={classnames({
                            refunded:
                              produit.amountRefunded &&
                              produit.amountRefunded > 0,
                          })}
                        >
                          {produit.qty} x {produit.price}€
                        </span>
                      </p>
                      {produit.variantLibelle && (
                        <span>{produit.variantLibelle}</span>
                      )}
                    </div>
                  </li>
                );
              }),
            )}
        </ul>
      </div>
      <div className="flex items-end flex-col">
        <div className="total">
          <span className="montant">{item.amountAashed || item.total} €</span>
        </div>
        {((undefined !== item.amountAashedDelivery &&
          item.amountAashedDelivery > 0) ||
          (undefined !== item.deliveryCost && item.deliveryCost > 0)) && (
          <div className="delivery_cost text-sm">
            Livraison : {item.amountAashedDelivery || item.deliveryCost}€
          </div>
        )}

        {item.deliveryMethod && 'store' === item.deliveryMethod && (
          <div className="delivery_store text-sm">Retrait au magasin</div>
        )}
      </div>

      <div className="indicator" />
    </ListItem>
  );
};

export default Item;
