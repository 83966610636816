import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';

const DateSC = styled.div`
  background: #e4e7ed;
  text-align: center;
  padding: 0.375rem;
  border-radius: 4px;
  height: 60px;
  width: 50px;
  box-sizing: border-box;
  color: hsla(0, 0%, 0%, 0.7) !important;
  line-height: 13px;
  .day {
    font-size: 18px;
    line-height: 22px;
    display: block;
  }
  .month {
    font-size: 12px;
    display: block;
  }
  .time {
    font-size: 11px;
  }
`;

const ListItemDate: FC<{ className?: string; date: string }> = ({
  className,
  date,
}) => (
  <DateSC className={className}>
    <span className="day">{moment(date).format('Do')}</span>
    <span className="month">{moment(date).format('MMM')}</span>
    <span className="time">{moment(date).format('HH:mm')}</span>
  </DateSC>
);

export default ListItemDate;
